<template>
  <div id="tracking-list">
    <Navigation />
    <v-main class="content mb-9" style="position: relative">
      <v-container fluid>
        <v-card style="border-radius: 2px" color="#383E45">
          <v-container fluid class="py-5 px-6">
            <HeaderPage title="รายการ Tracking Number" />

            <v-col cols="12" class="px-0 mb-7">
              <v-row>
                <v-col cols="12" md="6">
                  <Card
                    title="Tracking Number"
                    :subtitle="`${totalElement} รายการ`"
                    :icon="require('@/assets/tracking.png')"
                    bg_card="#fff"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <Card
                    title="ลูกค้า"
                    :subtitle="`${totalCustomer} คน`"
                    :icon="require('@/assets/rating.png')"
                    bg_card="#fff"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="px-0">
              <v-row>
                <v-col cols="12" lg="4">
                  <label>ค้นหาชื่อ-นามสกุล หรือเบอร์โทรศัพท์</label>
                  <v-text-field
                    hide-details
                    solo
                    dense
                    class="mt-1"
                    v-model="params.customer"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="params.date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <label>ค้นหาด้วยวันที่</label>
                      <v-text-field
                        v-model="dateRangeText"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        solo
                        dense
                        class="mt-1"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="params.date"
                      no-title
                      scrollable
                      range
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(params.date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                  class="mt-lg-7 d-flex justify-end justify-lg-start"
                >
                  <v-btn
                    color="blue"
                    height="38px"
                    @click.prevent="getTrackingOrders()"
                  >
                    <v-icon left>mdi-magnify</v-icon>
                    ค้นหา
                  </v-btn>
                  <v-btn
                    @click.prevent="defaultTrackingOrders"
                    class="ml-4"
                    height="38px"
                    color="white"
                    outlined
                    >ค่าเริ่มต้น</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>

            <div class="wrapper-table mb-1 mt-3">
              <table class="table-table">
                <tr class="table-head">
                  <th>#</th>
                  <th>ชื่อ-นามสกุล</th>
                  <th>เบอรโทรศัพท์</th>
                  <th>Tracking Number</th>
                  <th>สินค้าที่สั่ง</th>
                  <th>จำนวน</th>
                  <th>ขนส่ง</th>
                  <th>หมายเหตุ</th>
                  <th>วันที่</th>
                  <th>จัดการ</th>
                </tr>
                <tr v-if="trackingOrders.length === 0">
                  <td colspan="10">
                    <template>
                      <div
                        style="font-size: 20px"
                        class="d-flex flex-column align-center red--text my-4"
                      >
                        <v-img
                          class="mt-2 mb-4"
                          width="200"
                          src="@/assets/no-data.svg"
                          alt=""
                        >
                        </v-img>
                        ไม่พบข้อมูลรายการ Tracking Number
                      </div>
                    </template>
                  </td>
                </tr>
                <tr
                  class="table-body"
                  v-for="(data, index) in trackingOrders"
                  :key="index"
                >
                  <td>
                    {{ index + 1 + (pages * params.limit - params.limit) }}
                  </td>
                  <td>{{ data.first_name }} {{ data.last_name }}</td>
                  <td>{{ data.phone }}</td>
                  <td>{{ data.trackingNumber }}</td>
                  <td style="white-space: pre-line">
                    <v-chip
                      style="margin: 1px 2px"
                      small
                      v-for="(item, i) in data.products"
                      :key="i"
                      >{{ item.product_name }}</v-chip
                    >
                  </td>
                  <td
                    v-if="data.products[0].product_quantity"
                    style="white-space: pre-line"
                  >
                    <v-chip
                      style="margin: 1px 2px; margin-left: 0"
                      small
                      v-for="(item, i) in data.products"
                      :key="i"
                      >{{ item.product_quantity }}</v-chip
                    >
                  </td>
                  <td v-else>-</td>
                  <td>{{ data.logistics }}</td>
                  <td>{{ data.remark ? data.remark : '-' }}</td>
                  <td>{{ data.date | formatDate }}</td>
                  <td>
                    <v-btn
                      class="white--text"
                      small
                      color="warning"
                      @click.prevent="onOpenModalEdit(data)"
                      >แก้ไข</v-btn
                    >

                    <v-btn
                      @click.prevent="delTrackingList(data.order_id)"
                      class="ml-2 white--text"
                      small
                      color="red"
                      >ลบ</v-btn
                    >
                  </td>
                </tr>
              </table>
            </div>
            <div class="text-center mt-2" v-if="trackingOrders.length > 0">
              <v-pagination
                v-model="params.page"
                :length="totalPages"
                :total-visible="7"
                @input="getTrackingOrders"
              ></v-pagination>
            </div>
          </v-container>
        </v-card>
      </v-container>

      <div class="modal-edit-tracking-list" v-if="modal === true">
        <div class="modal-title mt-1 mb-4">แก้ไขข้อมูล</div>
        <v-divider color="white" class="mb-4"></v-divider>
        <v-form
          v-on:submit.prevent="submitEditTracking()"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col cols="12" class="pa-0">
            <v-row>
              <v-col cols="12" md="6" class="pb-1">
                <label>เบอร์โทรศัพท์ลูกค้าใหม่</label>
                <div class="d-flex">
                  <v-text-field
                    type="number"
                    class="phone-user-input mt-2"
                    solo
                    dense
                    v-model="dataTracking.phone"
                    :rules="phoneRule"
                    required
                  ></v-text-field>
                  >
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="px-0">
            <v-row>
              <v-col cols="12" md="6">
                <label for="">ชื่อจริง</label>
                <v-text-field
                  class="mt-2"
                  solo
                  dense
                  v-model="dataTracking.first_name"
                  :rules="firstNameRule"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">นามสกุล</label>
                <v-text-field
                  class="mt-2"
                  solo
                  dense
                  v-model="dataTracking.last_name"
                  :rules="lastNameRule"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-row>
              <v-col cols="12" md="5">
                <label for="">Tracking Number</label>
                <v-text-field
                  class="mt-2"
                  solo
                  dense
                  v-model="dataTracking.orders.trackingNumber"
                  :rules="trackingRule"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <label for="">ชื่อสินค้าที่สั่ง</label>
                <v-autocomplete
                  :rules="productsRule"
                  class="mt-2"
                  solo
                  dense
                  :items="productItems"
                  item-value="product_id"
                  item-text="product_name"
                  multiple
                  v-model="dataTracking.orders.products"
                  return-object
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <label for="">จำนวน</label>
                <v-text-field
                  type="number"
                  solo
                  dense
                  class="mt-2"
                  v-model="dataTracking.orders.quantity"
                  :rules="quantityRule"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pt-0 px-0">
            <v-row>
              <v-col cols="12" md="6">
                <label for="">ชื่อขนส่ง</label>
                <v-select
                  :items="$store.getters.getLogisticsName"
                  v-model="dataTracking.logistics"
                  solo
                  dense
                  class="mt-2"
                  :rules="logisticsRule"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">หมายเหตุ</label>
                <v-text-field
                  v-model="dataTracking.remark"
                  solo
                  dense
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xl="12" class="px-0 text-right">
            <v-btn
              :loading="loading"
              large
              color="blue"
              @click.prevent="submitEditTracking()"
            >
              <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>
              บันทึกการแก้ไข
            </v-btn>
            <v-btn
              class="ml-6"
              large
              outlined
              color="red"
              @click.prevent="closeModal()"
              >ยกเลิก</v-btn
            >
          </v-col>
        </v-form>
      </div>
    </v-main>
    <FooterAdmin />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import FooterAdmin from '@/components/FooterAdmin';
import HeaderPage from '@/components/HeaderPage';
import Card from '@/components/Card';

import TrackingService from '@/services/Tracking.service';
import ProductService from '@/services/Product.service';

import moment from 'moment';

import Swal from 'sweetalert2';
export default {
  name: 'TrackingList',
  components: {
    Navigation,
    FooterAdmin,
    HeaderPage,
    Card,
  },
  data() {
    return {
      menu: false,
      trackingOrders: [],
      pages: 1,
      params: {
        customer: '',
        date: [],
        page: 1,
        limit: 50,
      },
      totalPages: 1,
      totalElement: 0,
      totalCustomer: 0,

      modal: false,
      loading: false,
      valid: true,
      productItems: [],

      phoneRule: [(v) => !!v || 'กรุณากรอกเบอร์โทรศัพท์ลูกค้า'],
      firstNameRule: [(v) => !!v || 'กรุณากรอกชื่อจริงของลูกค้า'],
      lastNameRule: [(v) => !!v || 'กรุณากรอกนามสกุลของลูกค้า'],
      trackingRule: [(v) => !!v || 'กรุณากรอกหมายเลข Tracking Number'],
      productsRule: [(v) => !!v || 'กรุณาเลือกสินค้า'],
      quantityRule: [(v) => !!v || 'กรุณาระบุจำนวน'],
      logisticsRule: [(v) => !!v || 'กรุณาเลือกขนส่งที่ใช้บริการ'],
      dataTracking: {
        order_id: '',
        id: null,
        first_name: '',
        last_name: '',
        phone: '',
        logistics: '',
        orders: { trackingNumber: null, products: null, quantity: 1 },
        remark: '',
      },
    };
  },
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  computed: {
    dateRangeText() {
      return this.params.date.join(' - ');
    },
  },
  created() {
    this.getTrackingOrders();
  },
  methods: {
    async getTrackingOrders() {
      try {
        const response = await TrackingService.findAll(this.params);
        this.trackingOrders = response.orders;
        this.totalPages = response.totalPages;
        this.totalElement = response.totalElement;
        this.totalCustomer = response.totalCustomer;
        this.pages = this.params.page;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    defaultTrackingOrders() {
      this.params = {
        customer: '',
        date: [],
        page: 1,
        limit: 50,
      };
      this.getTrackingOrders();
    },
    onOpenModalEdit(data) {
      this.getProducts();
      this.modal = true;
      this.dataTracking.order_id = data.order_id;
      this.dataTracking.phone = data.phone;
      this.dataTracking.id = data.id;
      this.dataTracking.first_name = data.first_name;
      this.dataTracking.last_name = data.last_name;
      this.dataTracking.logistics = data.logistics;
      this.dataTracking.remark = data.remark;
      this.dataTracking.orders.trackingNumber = data.trackingNumber;
      this.dataTracking.orders.products = data.products;
      this.dataTracking.orders.quantity = data.quantity;
    },
    async submitEditTracking() {
      try {
        const response = await TrackingService.update(this.dataTracking);
        Swal.fire({
          position: 'bottom-end',
          toast: true,
          icon: 'success',
          title: response.msg,
          showConfirmButton: false,
          timer: 3000,
        });
        this.getTrackingOrders();
        this.modal = false;
      } catch (error) {
        this.modal = false;
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    closeModal() {
      this.modal = false;
    },
    async getProducts() {
      try {
        const response = await ProductService.findAll();
        this.productItems = response.map((data) => {
          return { product_id: data.id, product_name: data.product_name };
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    delTrackingList(order_id) {
      Swal.fire({
        icon: 'question',
        title: 'ยืนยันการลบ?',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        confirmButtonColor: '#2196F3',
        cancelButtonColor: '#90A4AE',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await TrackingService.delete(order_id);
            this.getTrackingOrders();
            Swal.fire({
              position: 'bottom-end',
              toast: true,
              icon: 'success',
              title: response.msg,
              showConfirmButton: false,
              timer: 3000,
            });
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: error.msg,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.modal-edit-tracking-list {
  width: 75%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: rgba(32, 34, 38, 0.98);
  padding: 1rem 1.2rem;
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  z-index: 999 !important;
}
.modal-title {
  color: #fff;
  font-size: 22px;
}
</style>
