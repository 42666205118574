<template>
  <v-card id="card" class="wrapper-card d-flex" :color="bg_card" height="110px">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="card-title mb-1">{{
          title
        }}</v-list-item-title>
        <v-list-item-subtitle class="card-sub-title mt-2">{{
          subtitle | currency2
        }}</v-list-item-subtitle>
      </v-list-item-content>

      <div style="padding: 5px 15px;">
        <v-img :src="icon" width="80px"> </v-img>
      </div>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: 'Classroom',
  props: ['title', 'subtitle', 'icon', 'bg_card'],
};
</script>

<style>
.wrapper-card {
  border-radius: 2px !important;
}
.card-title {
  font-size: 22px;
}
.card-sub-title {
  font-size: 20px;
}
</style>
